import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { FaPlus, FaTimes } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

const IssueInventoryForm = ({ fetchTotalInventory }) => {
  const [employees, setEmployees] = useState([]); // Store employees from API
  const [employeeName, setEmployeeName] = useState('');
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [details, setDetails] = useState('');
  const [issuedBy, setIssuedBy] = useState('');
  const [loading, setLoading] = useState(false);

  const inventoryItems = ['Headphones', 'Laptops', 'Mouse', 'Keyboards', 'PCs', 'LCDs'];

  // Fetch employees from API on component mount
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('https://backendmd.marsbpo.org/api/employees/getAllEmployees', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        // If response.data is already an array, assign it directly
        if (Array.isArray(response.data)) {
          setEmployees(response.data);
        } else {
          setEmployees([]); // Ensure it's always an array
        }
      } catch (error) {
        toast.error('Failed to fetch employees');
        setEmployees([]); // Prevent crashing by ensuring it's an array
      }
    };

    fetchEmployees();
  }, []);

  const handleAddItem = () => {
    if (!itemName) {
      toast.error('Please select an item');
      return;
    }
    if (quantity <= 0) {
      toast.error('Quantity must be greater than 0');
      return;
    }

    setItems([...items, { itemName, quantity, details }]);
    setItemName('');
    setQuantity(0);
    setDetails('');
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!employeeName) {
      toast.error('Please select an employee');
      return;
    }
    if (items.length === 0) {
      toast.error('Please add at least one item');
      return;
    }
    if (!issuedBy) {
      toast.error('Please enter issued by');
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post('https://backendmd.marsbpo.org/api/issue-inventory/issue', {
        employeeName,
        items,
        issuedBy
      });

      toast.success(response.data.message);
      setEmployeeName('');
      setItems([]);
      setIssuedBy('');

      fetchTotalInventory();
    } catch (error) {
      toast.error('Failed to issue inventory');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-8">
      <ToastContainer />
      <form onSubmit={handleSubmit} className="max-w-md shadow-xl rounded-lg p-6 mx-auto space-y-4">
        <h2 className="text-2xl font-bold">Issue Inventory</h2>
        <div>
          <label htmlFor="employeeName" className="block">Employee Name</label>
          <select
            id="employeeName"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            className="w-full px-4 py-2 border-b rounded border-gray-300 focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Employee</option>
            {employees.length > 0 ? (
              employees.map((emp, index) => (
                <option key={emp.id || index} value={emp.employeeName}>
                  {emp.employeeName}
                </option>
              ))
            ) : (
              <option disabled>Loading employees...</option>
            )}
          </select>
        </div>
        <div>
          <label htmlFor="itemName" className="block">Item Name</label>
          <select
            id="itemName"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            className="w-full px-4 py-2 border-b rounded border-gray-300 focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Item</option>
            {inventoryItems.map(item => (
              <option key={item} value={item}>{item}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="quantity" className="block">Quantity</label>
          <input
            type="number"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            className="w-full px-4 py-2 border-b rounded border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="details" className="block">Item Details</label>
          <textarea
            id="details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            className="w-full px-4 py-2 border-b rounded border-gray-300 focus:outline-none focus:border-blue-500"
            placeholder="Enter item details (optional)"
          />
        </div>
        <div>
          <label htmlFor="issuedBy" className="block">Issued By</label>
          <input
            type="text"
            id="issuedBy"
            value={issuedBy}
            onChange={(e) => setIssuedBy(e.target.value)}
            className="w-full px-4 py-2 border-b rounded border-gray-300 focus:outline-none focus:border-blue-500"
          />
        </div>
        <button
          type="button"
          onClick={handleAddItem}
          className="w-full flex items-center justify-center bg-blue-950 text-white py-2 px-4 rounded focus:outline-none hover:bg-blue-700"
        >
          <FaPlus className="mr-2" />
          Add Item
        </button>
        <ul className="text-center">
          {items.map((item, index) => (
            <li key={index} className="flex flex-col items-start justify-between p-2 border rounded mb-2">
              <span className="font-semibold">{item.itemName}: {item.quantity}</span>
              <span className="text-sm italic text-gray-600">{item.details || "No details provided"}</span>
              <button type="button" onClick={() => handleRemoveItem(index)} className="self-end">
                <FaTimes />
              </button>
            </li>
          ))}
        </ul>
        <button
          type="submit"
          className="w-full bg-blue-950 text-white py-2 px-4 rounded focus:outline-none hover:bg-blue-700"
        >
          {loading ? 'Loading...' : 'Issue Inventory'}
        </button>
      </form>
    </div>
  );
};

export default IssueInventoryForm;
