
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import logo from "../assets/logo.png"; // Ensure you have a logo in your assets

const AttendanceSearch = () => {
  const [employeeName, setEmployeeName] = useState("");
  const [employees, setEmployees] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [department, setDepartment] = useState("");
  const [departments, setDepartments] = useState([]);
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found. Please log in.");
        const response = await axios.get("https://backendmd.marsbpo.org/api/attendance/getDepartments", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found. Please log in.");
        const response = await axios.get("https://backendmd.marsbpo.org/api/employees/getAllEmployees", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchDepartments();
    fetchEmployees();
  }, []);

  const handleSearch = async () => {
    try {
      if (!startDate || !endDate) throw new Error("Please select a start and end date.");
      setIsLoading(true);
      const payload = { startDate, endDate, employeeName, department };
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token not found. Please log in.");
      const response = await axios.post("https://backendmd.marsbpo.org/api/attendance/getAttendanceDetailsByName", payload, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      });
      setAttendanceDetails(response.data);
      toast.success("Attendance details fetched successfully.");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const groupByDate = () => {
    return attendanceDetails.reduce((acc, entry) => {
      if (!acc[entry.date]) {
        acc[entry.date] = [];
      }
      acc[entry.date].push(entry);
      return acc;
    }, {});
  };

  const generatePDF = async () => {
    if (attendanceDetails.length === 0) {
      toast.error("No data available to generate PDF.");
      return;
    }

    const content = generatePDFContent();
    const element = document.createElement("div");
    element.innerHTML = content;

    await html2pdf(element, {
      margin: 10,
      filename: "attendance_report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    });
  };

  const generatePDFContent = () => {
    const today = new Date().toLocaleDateString();
  
    let content = `
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; padding: 30px; color: #333; }
          .header { text-align: center; margin-bottom: 40px; }
          .logo { width: 100px; height: 100px; display: block; margin: 0 auto 15px; }
          .title { font-size: 26px; font-weight: bold; color: #2c3e50; }
          .date { font-size: 18px; font-weight: bold; color: #e74c3c; margin-top: 10px; }
          .table-container { margin-top: 20px; }
          table { width: 100%; border-collapse: collapse; margin-top: 15px; }
          th, td { padding: 12px; border: 1px solid #ddd; text-align: left; }
          th { background-color: #34495e; color: white; font-size: 14px; text-transform: uppercase; }
          td { font-size: 13px; }
          tr:nth-child(even) { background-color: #f9f9f9; }
          tr:hover { background-color: #f1f1f1; }
          h3 { margin-top: 20px; color: #2980b9; font-size: 20px; }
        </style>
      </head>
      <body>
        <div class="header">
          <img src="${logo}" class="logo" alt="Company Logo" />
          <div class="title">Attendance Report</div>
          <div class="date">Generated on: ${today}</div>
        </div>
    `;
  
    Object.entries(groupByDate()).forEach(([date, entries]) => {
      content += `
        <div class="table-container">
          <h3>${date}</h3>
          <table>
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Department</th>
                <th>Status</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Total Hours</th>
              </tr>
            </thead>
            <tbody>
      `;
  
      entries.forEach((attendance) => {
        content += `
          <tr>
            <td>${attendance.name}</td>
            <td>${attendance.department}</td>
            <td>${attendance.status}</td>
            <td>${attendance.startTime || "N/A"}</td>
            <td>${attendance.endTime || "N/A"}</td>
            <td>${attendance.totalHours ? `${attendance.totalHours} hrs` : "N/A"}</td>
          </tr>
        `;
      });
  
      content += `
            </tbody>
          </table>
        </div>
      `;
    });
  
    content += `</body></html>`;
    return content;
  };
  

  return (
    <div className="max-w-screen-lg mx-auto p-4 mt-12">
      <div className="bg-white border-t-4 border-blue-950 rounded-lg shadow-md p-4">
        <div className="flex flex-col space-y-4 md:flex-row md:space-x-4">
          <select value={employeeName} onChange={(e) => setEmployeeName(e.target.value)} className="border rounded p-2">
            <option value="">All Employees</option>
            {employees.map((emp, index) => (
              <option key={index} value={emp.employeeName}>
                {emp.employeeName}
              </option>
            ))}
          </select>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="border rounded p-2" />
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="border rounded p-2" />
          <select value={department} onChange={(e) => setDepartment(e.target.value)} className="border rounded p-2">
            <option value="">All Departments</option>
            {departments.map((dept, index) => (
              <option key={index} value={dept.department}>
                {dept.department}
              </option>
            ))}
          </select>
          <button onClick={handleSearch} className="bg-blue-950 text-white rounded px-4 py-2">Search</button>
        </div>

        {attendanceDetails.length > 0 && (
          <button onClick={generatePDF} className="bg-green-500 text-white rounded px-6 py-2 mt-4 hover:bg-green-400">
            Generate PDF Report
          </button>
        )}
      </div>
        {/* Attendance Data */}
        <div className="mt-4" ref={tableRef}>
          <h2 className="text-lg font-semibold mb-2">Attendance Details:</h2>
          {attendanceDetails.length > 0 ? (
            Object.entries(groupByDate()).map(([date, entries]) => (
              <div key={date} className="mt-4">
                <h3 className="text-xl font-bold text-gray-700">{date}</h3>
                <table className="min-w-full divide-y divide-gray-200 mt-2">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Employee Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Department
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Start Time
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        End Time
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Hours
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {entries.map((attendance, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">{attendance.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{attendance.department}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{attendance.status}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{attendance.startTime || "N/A"}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{attendance.endTime || "N/A"}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {attendance.totalHours ? `${attendance.totalHours} hrs` : "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))
          ) : (
            <p className="text-red-500 font-bold">No attendance data available.</p>
          )}
        </div>
      </div>
  );
};

export default AttendanceSearch;
